import './styles/style.scss';
import Plan from './pages/Plan';

function App() {
  return (
    <Plan />
  );
}

export default App;
